export const CARDS = [{"name":"Abandoned Mine","set":"Dark Ages","cost":"0.00","supply":false,"landscape":false,"image_name":"abandoned_mine.jpg","types":["Action","Ruins"]},
{"name":"Academy","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"academy.jpg","types":["Project"]},
{"name":"Acting Troupe","set":"Renaissance","cost":"3.00","supply":true,"landscape":false,"image_name":"acting_troupe.jpg","types":["Action"]},
{"name":"Advance","set":"Empires","cost":"0.00","supply":true,"landscape":true,"image_name":"advance.jpg","types":["Event"]},
{"name":"Adventurer","set":"Base, 1E","cost":"6.00","supply":true,"landscape":false,"image_name":"adventurer.jpg","types":["Action"]},
{"name":"Advisor","set":"Guilds","cost":"4.00","supply":true,"landscape":false,"image_name":"advisor.jpg","types":["Action"]},
{"name":"Alchemist","set":"Alchemy","cost":"3.50","supply":true,"landscape":false,"image_name":"alchemist.jpg","types":["Action"]},
{"name":"Alliance","set":"Menagerie","cost":"0.00","supply":true,"landscape":true,"image_name":"alliance.jpg","types":["Event"]},
{"name":"Alms","set":"Adventures","cost":"0.00","supply":true,"landscape":true,"image_name":"alms.jpg","types":["Event"]},
{"name":"Altar","set":"Dark Ages","cost":"6.00","supply":true,"landscape":false,"image_name":"altar.jpg","types":["Action"]},
{"name":"Ambassador","set":"Seaside","cost":"3.00","supply":true,"landscape":false,"image_name":"ambassador.jpg","types":["Action","Attack"]},
{"name":"Amulet","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"amulet.jpg","types":["Action","Duration"]},
{"name":"Animal Fair","set":"Menagerie","cost":"7.00","supply":true,"landscape":false,"image_name":"animal_fair.jpg","types":["Action"]},
{"name":"Annex","set":"Empires","cost":"8.00","supply":true,"landscape":true,"image_name":"annex.jpg","types":["Event"]},
{"name":"Apothecary","set":"Alchemy","cost":"2.50","supply":true,"landscape":false,"image_name":"apothecary.jpg","types":["Action"]},
{"name":"Apprentice","set":"Alchemy","cost":"5.00","supply":true,"landscape":false,"image_name":"apprentice.jpg","types":["Action"]},
{"name":"Aqueduct","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"aqueduct.jpg","types":["Landmark"]},
{"name":"Archive","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"archive.jpg","types":["Action","Duration"]},
{"name":"Arena","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"arena.jpg","types":["Landmark"]},
{"name":"Armory","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"armory.jpg","types":["Action"]},
{"name":"Artificer","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"artificer.jpg","types":["Action"]},
{"name":"Artisan","set":"Base, 2E","cost":"6.00","supply":true,"landscape":false,"image_name":"artisan.jpg","types":["Action"]},
{"name":"Avanto","set":"Promo","cost":"5.00","supply":true,"landscape":false,"image_name":"avanto.jpg","types":["Action"]},
{"name":"Bad Omens","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"bad_omens.jpg","types":["Hex"]},
{"name":"Bag of Gold","set":"Cornucopia","cost":"0.00","supply":true,"landscape":false,"image_name":"bag_of_gold.jpg","types":["Action","Prize"]},
{"name":"Baker","set":"Guilds","cost":"5.00","supply":true,"landscape":false,"image_name":"baker.jpg","types":["Action"]},
{"name":"Ball","set":"Adventures","cost":"5.00","supply":true,"landscape":true,"image_name":"ball.jpg","types":["Event"]},
{"name":"Bandit","set":"Base, 2E","cost":"5.00","supply":true,"landscape":false,"image_name":"bandit.jpg","types":["Action","Attack"]},
{"name":"Bandit Camp","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"bandit_camp.jpg","types":["Action"]},
{"name":"Bandit Fort","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"bandit_fort.jpg","types":["Landmark"]},
{"name":"Band of Misfits","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"band_of_misfits.jpg","types":["Action","Command"]},
{"name":"Banish","set":"Menagerie","cost":"4.00","supply":true,"landscape":true,"image_name":"banish.jpg","types":["Event"]},
{"name":"Bank","set":"Prosperity","cost":"7.00","supply":true,"landscape":false,"image_name":"bank.jpg","types":["Treasure"]},
{"name":"Banquet","set":"Empires","cost":"3.00","supply":true,"landscape":true,"image_name":"banquet.jpg","types":["Event"]},
{"name":"Bard","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"bard.jpg","types":["Action","Fate"]},
{"name":"Bargain","set":"Menagerie","cost":"4.00","supply":true,"landscape":true,"image_name":"bargain.jpg","types":["Event"]},
{"name":"Barge","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"barge.jpg","types":["Action","Duration"]},
{"name":"Baron","set":"Intrigue","cost":"4.00","supply":true,"landscape":false,"image_name":"baron.jpg","types":["Action"]},
{"name":"Barracks","set":"Renaissance","cost":"6.00","supply":true,"landscape":true,"image_name":"barracks.jpg","types":["Project"]},
{"name":"Basilica","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"basilica.jpg","types":["Landmark"]},
{"name":"Bat","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"bat.jpg","types":["Night"]},
{"name":"Baths","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"baths.jpg","types":["Landmark"]},
{"name":"Battlefield","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"battlefield.jpg","types":["Landmark"]},
{"name":"Bazaar","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"bazaar.jpg","types":["Action"]},
{"name":"Beggar","set":"Dark Ages","cost":"2.00","supply":true,"landscape":false,"image_name":"beggar.jpg","types":["Action","Reaction"]},
{"name":"Bishop","set":"Prosperity","cost":"4.00","supply":true,"landscape":false,"image_name":"bishop.jpg","types":["Action"]},
{"name":"Black Cat","set":"Menagerie","cost":"2.00","supply":true,"landscape":false,"image_name":"black_cat.jpg","types":["Action","Reaction"]},
{"name":"Black Market","set":"Promo","cost":"3.00","supply":true,"landscape":false,"image_name":"black_market.jpg","types":["Action"]},
{"name":"Blessed Village","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"blessed_village.jpg","types":["Action","Fate"]},
{"name":"Bonfire","set":"Adventures","cost":"3.00","supply":true,"landscape":true,"image_name":"bonfire.jpg","types":["Event"]},
{"name":"Border Guard","set":"Renaissance","cost":"2.00","supply":true,"landscape":false,"image_name":"border_guard.jpg","types":["Action"]},
{"name":"Border Village","set":"Hinterlands","cost":"6.00","supply":true,"landscape":false,"image_name":"border_village.jpg","types":["Action"]},
{"name":"Borrow","set":"Adventures","cost":"0.00","supply":true,"landscape":true,"image_name":"borrow.jpg","types":["Event"]},
{"name":"Bounty Hunter","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"bounty_hunter.jpg","types":["Action"]},
{"name":"Bridge","set":"Intrigue","cost":"4.00","supply":true,"landscape":false,"image_name":"bridge.jpg","types":["Action"]},
{"name":"Bridge Troll","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"bridge_troll.jpg","types":["Action","Attack","Duration"]},
{"name":"Bureaucrat","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"bureaucrat.jpg","types":["Action","Attack"]},
{"name":"Bustling Village","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"bustling_village.jpg","types":["Action"]},
{"name":"Butcher","set":"Guilds","cost":"5.00","supply":true,"landscape":false,"image_name":"butcher.jpg","types":["Action"]},
{"name":"Cache","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"cache.jpg","types":["Treasure"]},
{"name":"Camel Train","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"camel_train.jpg","types":["Action"]},
{"name":"Canal","set":"Renaissance","cost":"7.00","supply":true,"landscape":true,"image_name":"canal.jpg","types":["Project"]},
{"name":"Candlestick Maker","set":"Guilds","cost":"2.00","supply":true,"landscape":false,"image_name":"candlestick_maker.jpg","types":["Action"]},
{"name":"Capital","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"capital.jpg","types":["Treasure"]},
{"name":"Capitalism","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"capitalism.jpg","types":["Project"]},
{"name":"Captain","set":"Promo","cost":"6.00","supply":true,"landscape":false,"image_name":"captain.jpg","types":["Action","Duration","Command"]},
{"name":"Caravan","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"caravan.jpg","types":["Action","Duration"]},
{"name":"Caravan Guard","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"caravan_guard.jpg","types":["Action","Duration","Reaction"]},
{"name":"Cardinal","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"cardinal.jpg","types":["Action","Attack"]},
{"name":"Cargo Ship","set":"Renaissance","cost":"3.00","supply":true,"landscape":false,"image_name":"cargo_ship.jpg","types":["Action","Duration"]},
{"name":"Cartographer","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"cartographer.jpg","types":["Action"]},
{"name":"Castles","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"castles.jpg","types":["Victory"]},
{"name":"Catacombs","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"catacombs.jpg","types":["Action"]},
{"name":"Catapult","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"catapult.jpg","types":["Action","Attack"]},
{"name":"Cathedral","set":"Renaissance","cost":"3.00","supply":true,"landscape":true,"image_name":"cathedral.jpg","types":["Project"]},
{"name":"Cavalry","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"cavalry.jpg","types":["Action"]},
{"name":"Cellar","set":"Base","cost":"2.00","supply":true,"landscape":false,"image_name":"cellar.jpg","types":["Action"]},
{"name":"Cemetery","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"cemetery.jpg","types":["Victory"]},
{"name":"Champion","set":"Adventures","cost":"6.00","supply":true,"landscape":false,"image_name":"champion.jpg","types":["Action","Duration"]},
{"name":"Chancellor","set":"Base, 1E","cost":"3.00","supply":true,"landscape":false,"image_name":"chancellor.jpg","types":["Action"]},
{"name":"Changeling","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"changeling.jpg","types":["Night"]},
{"name":"Chapel","set":"Base","cost":"2.00","supply":true,"landscape":false,"image_name":"chapel.jpg","types":["Action"]},
{"name":"Chariot Race","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"chariot_race.jpg","types":["Action"]},
{"name":"Charm","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"charm.jpg","types":["Treasure"]},
{"name":"Church","set":"Promo","cost":"3.00","supply":true,"landscape":false,"image_name":"church.jpg","types":["Action","Duration"]},
{"name":"Citadel","set":"Renaissance","cost":"8.00","supply":true,"landscape":true,"image_name":"citadel.jpg","types":["Project"]},
{"name":"City","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"city.jpg","types":["Action"]},
{"name":"City Gate","set":"Renaissance","cost":"3.00","supply":true,"landscape":true,"image_name":"city_gate.jpg","types":["Project"]},
{"name":"City Quarter","set":"Empires","cost":"8.00","supply":true,"landscape":false,"image_name":"city_quarter.jpg","types":["Action"]},
{"name":"Cobbler","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"cobbler.jpg","types":["Night","Duration"]},
{"name":"Coin of the Realm","set":"Adventures","cost":"2.00","supply":true,"landscape":false,"image_name":"coin_of_the_realm.jpg","types":["Treasure","Reserve"]},
{"name":"Colonnade","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"colonnade.jpg","types":["Landmark"]},
{"name":"Colony","set":"Prosperity","cost":"1.00","supply":true,"landscape":false,"image_name":"colony.jpg","types":["Victory"]},
{"name":"Commerce","set":"Menagerie","cost":"5.00","supply":true,"landscape":true,"image_name":"commerce.jpg","types":["Event"]},
{"name":"Conclave","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"conclave.jpg","types":["Action"]},
{"name":"Conquest","set":"Empires","cost":"6.00","supply":true,"landscape":true,"image_name":"conquest.jpg","types":["Event"]},
{"name":"Conspirator","set":"Intrigue","cost":"4.00","supply":true,"landscape":false,"image_name":"conspirator.jpg","types":["Action"]},
{"name":"Contraband","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"contraband.jpg","types":["Treasure"]},
{"name":"Copper","set":"Base","cost":"0.00","supply":false,"landscape":false,"image_name":"copper.jpg","types":["Treasure"]},
{"name":"Coppersmith","set":"Intrigue, 1E","cost":"4.00","supply":true,"landscape":false,"image_name":"coppersmith.jpg","types":["Action"]},
{"name":"Council Room","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"council_room.jpg","types":["Action"]},
{"name":"Count","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"count.jpg","types":["Action"]},
{"name":"Counterfeit","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"counterfeit.jpg","types":["Treasure"]},
{"name":"Counting House","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"counting_house.jpg","types":["Action"]},
{"name":"Courtier","set":"Intrigue, 2E","cost":"5.00","supply":true,"landscape":false,"image_name":"courtier.jpg","types":["Action"]},
{"name":"Courtyard","set":"Intrigue","cost":"2.00","supply":true,"landscape":false,"image_name":"courtyard.jpg","types":["Action"]},
{"name":"Coven","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"coven.jpg","types":["Action","Attack"]},
{"name":"Crop Rotation","set":"Renaissance","cost":"6.00","supply":true,"landscape":true,"image_name":"crop_rotation.jpg","types":["Project"]},
{"name":"Crossroads","set":"Hinterlands","cost":"2.00","supply":true,"landscape":false,"image_name":"crossroads.jpg","types":["Action"]},
{"name":"Crown","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"crown.jpg","types":["Action","Treasure"]},
{"name":"Crumbling Castle","set":"Empires","cost":"4.00","supply":false,"landscape":false,"image_name":"crumbling_castle.jpg","types":["Victory","Castle"]},
{"name":"Crypt","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"crypt.jpg","types":["Night","Duration"]},
{"name":"Cultist","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"cultist.jpg","types":["Action","Attack","Looter"]},
{"name":"Curse","set":"Base","cost":"0.00","supply":false,"landscape":false,"image_name":"curse.jpg","types":["Curse"]},
{"name":"Cursed Gold","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"cursed_gold.jpg","types":["Treasure","Heirloom"]},
{"name":"Cursed Village","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"cursed_village.jpg","types":["Action","Doom"]},
{"name":"Cutpurse","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"cutpurse.jpg","types":["Action","Attack"]},
{"name":"Dame Anna","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"dame_anna.jpg","types":["Action","Attack","Knight"]},
{"name":"Dame Josephine","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"dame_josephine.jpg","types":["Action","Attack","Victory","Knight"]},
{"name":"Dame Molly","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"dame_molly.jpg","types":["Action","Attack","Knight"]},
{"name":"Dame Natalie","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"dame_natalie.jpg","types":["Action","Attack","Knight"]},
{"name":"Dame Sylvia","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"dame_sylvia.jpg","types":["Action","Attack","Knight"]},
{"name":"Death Cart","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"death_cart.jpg","types":["Action","Looter"]},
{"name":"Defiled Shrine","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"defiled_shrine.jpg","types":["Landmark"]},
{"name":"Delay","set":"Menagerie","cost":"0.00","supply":true,"landscape":true,"image_name":"delay.jpg","types":["Event"]},
{"name":"Deluded","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"deluded.jpg","types":["State"]},
{"name":"Delusion","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"delusion.jpg","types":["Hex"]},
{"name":"Delve","set":"Empires","cost":"2.00","supply":true,"landscape":true,"image_name":"delve.jpg","types":["Event"]},
{"name":"Demand","set":"Menagerie","cost":"5.00","supply":true,"landscape":true,"image_name":"demand.jpg","types":["Event"]},
{"name":"Den of Sin","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"den_of_sin.jpg","types":["Night","Duration"]},
{"name":"Desperation","set":"Menagerie","cost":"0.00","supply":true,"landscape":true,"image_name":"desperation.jpg","types":["Event"]},
{"name":"Destrier","set":"Menagerie","cost":"6.00","supply":true,"landscape":false,"image_name":"destrier.jpg","types":["Action"]},
{"name":"Develop","set":"Hinterlands","cost":"3.00","supply":true,"landscape":false,"image_name":"develop.jpg","types":["Action"]},
{"name":"Devil's Workshop","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"devil's_workshop.jpg","types":["Night"]},
{"name":"Diadem","set":"Cornucopia","cost":"0.00","supply":true,"landscape":false,"image_name":"diadem.jpg","types":["Treasure","Prize"]},
{"name":"Diplomat","set":"Intrigue, 2E","cost":"4.00","supply":true,"landscape":false,"image_name":"diplomat.jpg","types":["Action","Reaction"]},
{"name":"Disciple","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"disciple.jpg","types":["Action","Traveller"]},
{"name":"Dismantle","set":"Promo","cost":"4.00","supply":true,"landscape":false,"image_name":"dismantle.jpg","types":["Action"]},
{"name":"Displace","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"displace.jpg","types":["Action"]},
{"name":"Distant Lands","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"distant_lands.jpg","types":["Action","Reserve","Victory"]},
{"name":"Doctor","set":"Guilds","cost":"3.00","supply":true,"landscape":false,"image_name":"doctor.jpg","types":["Action"]},
{"name":"Dominate","set":"Empires","cost":"4.00","supply":true,"landscape":true,"image_name":"dominate.jpg","types":["Event"]},
{"name":"Donate","set":"Empires","cost":"8.00","supply":true,"landscape":true,"image_name":"donate.jpg","types":["Event"]},
{"name":"Druid","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"druid.jpg","types":["Action","Fate"]},
{"name":"Ducat","set":"Renaissance","cost":"2.00","supply":true,"landscape":false,"image_name":"ducat.jpg","types":["Treasure"]},
{"name":"Duchess","set":"Hinterlands","cost":"2.00","supply":true,"landscape":false,"image_name":"duchess.jpg","types":["Action"]},
{"name":"Duchy","set":"Base","cost":"5.00","supply":false,"landscape":false,"image_name":"duchy.jpg","types":["Victory"]},
{"name":"Duke","set":"Intrigue","cost":"5.00","supply":true,"landscape":false,"image_name":"duke.jpg","types":["Victory"]},
{"name":"Dungeon","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"dungeon.jpg","types":["Action","Duration"]},
{"name":"Duplicate","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"duplicate.jpg","types":["Action","Reserve"]},
{"name":"Embargo","set":"Seaside","cost":"2.00","supply":true,"landscape":false,"image_name":"embargo.jpg","types":["Action"]},
{"name":"Embassy","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"embassy.jpg","types":["Action"]},
{"name":"Emporium","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"emporium.jpg","types":["Action"]},
{"name":"Encampment","set":"Empires","cost":"2.00","supply":true,"landscape":false,"image_name":"encampment.jpg","types":["Action"]},
{"name":"Enchantress","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"enchantress.jpg","types":["Action","Attack","Duration"]},
{"name":"Enclave","set":"Menagerie","cost":"8.00","supply":true,"landscape":true,"image_name":"enclave.jpg","types":["Event"]},
{"name":"Engineer","set":"Empires","cost":"4.00","supply":true,"landscape":false,"image_name":"engineer.jpg","types":["Action"]},
{"name":"Enhance","set":"Menagerie","cost":"2.00","supply":true,"landscape":true,"image_name":"enhance.jpg","types":["Event"]},
{"name":"Envious","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"envious.jpg","types":["State"]},
{"name":"Envoy","set":"Promo","cost":"4.00","supply":true,"landscape":false,"image_name":"envoy.jpg","types":["Action"]},
{"name":"Envy","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"envy.jpg","types":["Hex"]},
{"name":"Estate","set":"Base","cost":"2.00","supply":false,"landscape":false,"image_name":"estate.jpg","types":["Victory"]},
{"name":"Exorcist","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"exorcist.jpg","types":["Night"]},
{"name":"Expand","set":"Prosperity","cost":"7.00","supply":true,"landscape":false,"image_name":"expand.jpg","types":["Action"]},
{"name":"Expedition","set":"Adventures","cost":"3.00","supply":true,"landscape":true,"image_name":"expedition.jpg","types":["Event"]},
{"name":"Experiment","set":"Renaissance","cost":"3.00","supply":true,"landscape":false,"image_name":"experiment.jpg","types":["Action"]},
{"name":"Exploration","set":"Renaissance","cost":"4.00","supply":true,"landscape":true,"image_name":"exploration.jpg","types":["Project"]},
{"name":"Explorer","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"explorer.jpg","types":["Action"]},
{"name":"Fair","set":"Renaissance","cost":"4.00","supply":true,"landscape":true,"image_name":"fair.jpg","types":["Project"]},
{"name":"Fairgrounds","set":"Cornucopia","cost":"6.00","supply":true,"landscape":false,"image_name":"fairgrounds.jpg","types":["Victory"]},
{"name":"Faithful Hound","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"faithful_hound.jpg","types":["Action","Reaction"]},
{"name":"Falconer","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"falconer.jpg","types":["Action","Reaction"]},
{"name":"Familiar","set":"Alchemy","cost":"3.50","supply":true,"landscape":false,"image_name":"familiar.jpg","types":["Action","Attack"]},
{"name":"Famine","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"famine.jpg","types":["Hex"]},
{"name":"Farmers' Market","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"farmers'_market.jpg","types":["Action","Gathering"]},
{"name":"Farming Village","set":"Cornucopia","cost":"4.00","supply":true,"landscape":false,"image_name":"farming_village.jpg","types":["Action"]},
{"name":"Farmland","set":"Hinterlands","cost":"6.00","supply":true,"landscape":false,"image_name":"farmland.jpg","types":["Victory"]},
{"name":"Fear","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"fear.jpg","types":["Hex"]},
{"name":"Feast","set":"Base, 1E","cost":"4.00","supply":true,"landscape":false,"image_name":"feast.jpg","types":["Action"]},
{"name":"Feodum","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"feodum.jpg","types":["Victory"]},
{"name":"Ferry","set":"Adventures","cost":"3.00","supply":true,"landscape":true,"image_name":"ferry.jpg","types":["Event"]},
{"name":"Festival","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"festival.jpg","types":["Action"]},
{"name":"Fisherman","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"fisherman.jpg","types":["Action"]},
{"name":"Fishing Village","set":"Seaside","cost":"3.00","supply":true,"landscape":false,"image_name":"fishing_village.jpg","types":["Action","Duration"]},
{"name":"Flag","set":"Renaissance","cost":null,"supply":false,"landscape":true,"image_name":"flag.jpg","types":["Artifact"]},
{"name":"Flag Bearer","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"flag_bearer.jpg","types":["Action"]},
{"name":"Fleet","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"fleet.jpg","types":["Project"]},
{"name":"Followers","set":"Cornucopia","cost":"0.00","supply":true,"landscape":false,"image_name":"followers.jpg","types":["Action","Attack","Prize"]},
{"name":"Fool","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"fool.jpg","types":["Action","Fate"]},
{"name":"Fool's Gold","set":"Hinterlands","cost":"2.00","supply":true,"landscape":false,"image_name":"fool's_gold.jpg","types":["Treasure","Reaction"]},
{"name":"Forager","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"forager.jpg","types":["Action"]},
{"name":"Forge","set":"Prosperity","cost":"7.00","supply":true,"landscape":false,"image_name":"forge.jpg","types":["Action"]},
{"name":"Fortress","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"fortress.jpg","types":["Action"]},
{"name":"Fortune","set":"Empires","cost":"16.00","supply":true,"landscape":false,"image_name":"fortune.jpg","types":["Treasure"]},
{"name":"Fortune Teller","set":"Cornucopia","cost":"3.00","supply":true,"landscape":false,"image_name":"fortune_teller.jpg","types":["Action","Attack"]},
{"name":"Forum","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"forum.jpg","types":["Action"]},
{"name":"Fountain","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"fountain.jpg","types":["Landmark"]},
{"name":"Fugitive","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"fugitive.jpg","types":["Action","Traveller"]},
{"name":"Gamble","set":"Menagerie","cost":"2.00","supply":true,"landscape":true,"image_name":"gamble.jpg","types":["Event"]},
{"name":"Gardens","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"gardens.jpg","types":["Victory"]},
{"name":"Gatekeeper","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"gatekeeper.jpg","types":["Action","Duration","Attack"]},
{"name":"Gear","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"gear.jpg","types":["Action","Duration"]},
{"name":"Ghost","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"ghost.jpg","types":["Night","Duration","Spirit"]},
{"name":"Ghost Ship","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"ghost_ship.jpg","types":["Action","Attack"]},
{"name":"Ghost Town","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"ghost_town.jpg","types":["Night","Duration"]},
{"name":"Giant","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"giant.jpg","types":["Action","Attack"]},
{"name":"Gladiator","set":"Empires","cost":"3.00","supply":true,"landscape":false,"image_name":"gladiator.jpg","types":["Action"]},
{"name":"Goat","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"goat.jpg","types":["Treasure","Heirloom"]},
{"name":"Goatherd","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"goatherd.jpg","types":["Action"]},
{"name":"Gold","set":"Base","cost":"6.00","supply":false,"landscape":false,"image_name":"gold.jpg","types":["Treasure"]},
{"name":"Golem","set":"Alchemy","cost":"4.50","supply":true,"landscape":false,"image_name":"golem.jpg","types":["Action"]},
{"name":"Goons","set":"Prosperity","cost":"6.00","supply":true,"landscape":false,"image_name":"goons.jpg","types":["Action","Attack"]},
{"name":"Governor","set":"Promo","cost":"5.00","supply":true,"landscape":false,"image_name":"governor.jpg","types":["Action"]},
{"name":"Grand Castle","set":"Empires","cost":"9.00","supply":false,"landscape":false,"image_name":"grand_castle.jpg","types":["Victory","Castle"]},
{"name":"Grand Market","set":"Prosperity","cost":"6.00","supply":true,"landscape":false,"image_name":"grand_market.jpg","types":["Action"]},
{"name":"Graverobber","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"graverobber.jpg","types":["Action"]},
{"name":"Great Hall","set":"Intrigue, 1E","cost":"3.00","supply":true,"landscape":false,"image_name":"great_hall.jpg","types":["Action","Victory"]},
{"name":"Greed","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"greed.jpg","types":["Hex"]},
{"name":"Groom","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"groom.jpg","types":["Action"]},
{"name":"Groundskeeper","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"groundskeeper.jpg","types":["Action"]},
{"name":"Guardian","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"guardian.jpg","types":["Night","Duration"]},
{"name":"Guide","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"guide.jpg","types":["Action","Reserve"]},
{"name":"Guildhall","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"guildhall.jpg","types":["Project"]},
{"name":"Haggler","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"haggler.jpg","types":["Action"]},
{"name":"Hamlet","set":"Cornucopia","cost":"2.00","supply":true,"landscape":false,"image_name":"hamlet.jpg","types":["Action"]},
{"name":"Harbinger","set":"Base, 2E","cost":"3.00","supply":true,"landscape":false,"image_name":"harbinger.jpg","types":["Action"]},
{"name":"Harem","set":"Intrigue","cost":"6.00","supply":true,"landscape":false,"image_name":"harem.jpg","types":["Treasure","Victory"]},
{"name":"Harvest","set":"Cornucopia","cost":"5.00","supply":true,"landscape":false,"image_name":"harvest.jpg","types":["Action"]},
{"name":"Haunted Castle","set":"Empires","cost":"6.00","supply":false,"landscape":false,"image_name":"haunted_castle.jpg","types":["Victory","Castle"]},
{"name":"Haunted Mirror","set":"Nocturne","cost":"0.00","supply":true,"landscape":false,"image_name":"haunted_mirror.jpg","types":["Treasure","Heirloom"]},
{"name":"Haunted Woods","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"haunted_woods.jpg","types":["Action","Attack","Duration"]},
{"name":"Haunting","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"haunting.jpg","types":["Hex"]},
{"name":"Haven","set":"Seaside","cost":"2.00","supply":true,"landscape":false,"image_name":"haven.jpg","types":["Action","Duration"]},
{"name":"Herald","set":"Guilds","cost":"4.00","supply":true,"landscape":false,"image_name":"herald.jpg","types":["Action"]},
{"name":"Herbalist","set":"Alchemy","cost":"2.00","supply":true,"landscape":false,"image_name":"herbalist.jpg","types":["Action"]},
{"name":"Hermit","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"hermit.jpg","types":["Action"]},
{"name":"Hero","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"hero.jpg","types":["Action","Traveller"]},
{"name":"Hideout","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"hideout.jpg","types":["Action"]},
{"name":"Highway","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"highway.jpg","types":["Action"]},
{"name":"Hireling","set":"Adventures","cost":"6.00","supply":true,"landscape":false,"image_name":"hireling.jpg","types":["Action","Duration"]},
{"name":"Hoard","set":"Prosperity","cost":"6.00","supply":true,"landscape":false,"image_name":"hoard.jpg","types":["Treasure"]},
{"name":"Horn","set":"Renaissance","cost":null,"supply":false,"landscape":true,"image_name":"horn.jpg","types":["Artifact"]},
{"name":"Horn of Plenty","set":"Cornucopia","cost":"5.00","supply":true,"landscape":false,"image_name":"horn_of_plenty.jpg","types":["Treasure"]},
{"name":"Horse","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"horse.jpg","types":["Action"]},
{"name":"Horse Traders","set":"Cornucopia","cost":"4.00","supply":true,"landscape":false,"image_name":"horse_traders.jpg","types":["Action","Reaction"]},
{"name":"Hostelry","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"hostelry.jpg","types":["Action"]},
{"name":"Hovel","set":"Dark Ages","cost":"1.00","supply":true,"landscape":false,"image_name":"hovel.jpg","types":["Reaction","Shelter"]},
{"name":"Humble Castle","set":"Empires","cost":"3.00","supply":false,"landscape":false,"image_name":"humble_castle.jpg","types":["Treasure","Victory","Castle"]},
{"name":"Hunting Grounds","set":"Dark Ages","cost":"6.00","supply":true,"landscape":false,"image_name":"hunting_grounds.jpg","types":["Action"]},
{"name":"Hunting Lodge","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"hunting_lodge.jpg","types":["Action"]},
{"name":"Hunting Party","set":"Cornucopia","cost":"5.00","supply":true,"landscape":false,"image_name":"hunting_party.jpg","types":["Action"]},
{"name":"Idol","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"idol.jpg","types":["Treasure","Attack","Fate"]},
{"name":"Ill-Gotten Gains","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"ill-gotten_gains.jpg","types":["Treasure"]},
{"name":"Imp","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"imp.jpg","types":["Action","Spirit"]},
{"name":"Improve","set":"Renaissance","cost":"3.00","supply":true,"landscape":false,"image_name":"improve.jpg","types":["Action"]},
{"name":"Inheritance","set":"Adventures","cost":"7.00","supply":true,"landscape":true,"image_name":"inheritance.jpg","types":["Event"]},
{"name":"Inn","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"inn.jpg","types":["Action"]},
{"name":"Innovation","set":"Renaissance","cost":"6.00","supply":true,"landscape":true,"image_name":"innovation.jpg","types":["Project"]},
{"name":"Inventor","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"inventor.jpg","types":["Action"]},
{"name":"Invest","set":"Menagerie","cost":"4.00","supply":true,"landscape":true,"image_name":"invest.jpg","types":["Event"]},
{"name":"Ironmonger","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"ironmonger.jpg","types":["Action"]},
{"name":"Ironworks","set":"Intrigue","cost":"4.00","supply":true,"landscape":false,"image_name":"ironworks.jpg","types":["Action"]},
{"name":"Island","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"island.jpg","types":["Action","Victory"]},
{"name":"Jack of All Trades","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"jack_of_all_trades.jpg","types":["Action"]},
{"name":"Jester","set":"Cornucopia","cost":"5.00","supply":true,"landscape":false,"image_name":"jester.jpg","types":["Action","Attack"]},
{"name":"Journeyman","set":"Guilds","cost":"5.00","supply":true,"landscape":false,"image_name":"journeyman.jpg","types":["Action"]},
{"name":"Junk Dealer","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"junk_dealer.jpg","types":["Action"]},
{"name":"Keep","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"keep.jpg","types":["Landmark"]},
{"name":"Key","set":"Renaissance","cost":null,"supply":false,"landscape":true,"image_name":"key.jpg","types":["Artifact"]},
{"name":"Kiln","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"kiln.jpg","types":["Action"]},
{"name":"King's Castle","set":"Empires","cost":"0.00","supply":false,"landscape":false,"image_name":"king's_castle.jpg","types":["Victory","Castle"]},
{"name":"King's Court","set":"Prosperity","cost":"7.00","supply":true,"landscape":false,"image_name":"king's_court.jpg","types":["Action"]},
{"name":"Knights","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"knights.jpg","types":["Action","Attack"]},
{"name":"Laboratory","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"laboratory.jpg","types":["Action"]},
{"name":"Labyrinth","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"labyrinth.jpg","types":["Landmark"]},
{"name":"Lackeys","set":"Renaissance","cost":"2.00","supply":true,"landscape":false,"image_name":"lackeys.jpg","types":["Action"]},
{"name":"Lantern","set":"Renaissance","cost":null,"supply":false,"landscape":true,"image_name":"lantern.jpg","types":["Artifact"]},
{"name":"Legionary","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"legionary.jpg","types":["Action","Attack"]},
{"name":"Leprechaun","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"leprechaun.jpg","types":["Action","Doom"]},
{"name":"Library","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"library.jpg","types":["Action"]},
{"name":"Lighthouse","set":"Seaside","cost":"2.00","supply":true,"landscape":false,"image_name":"lighthouse.jpg","types":["Action","Duration"]},
{"name":"Livery","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"livery.jpg","types":["Action"]},
{"name":"Loan","set":"Prosperity","cost":"3.00","supply":true,"landscape":false,"image_name":"loan.jpg","types":["Treasure"]},
{"name":"Locusts","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"locusts.jpg","types":["Hex"]},
{"name":"Lookout","set":"Seaside","cost":"3.00","supply":true,"landscape":false,"image_name":"lookout.jpg","types":["Action"]},
{"name":"Lost Arts","set":"Adventures","cost":"6.00","supply":true,"landscape":true,"image_name":"lost_arts.jpg","types":["Event"]},
{"name":"Lost City","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"lost_city.jpg","types":["Action"]},
{"name":"Lost in the Woods","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"lost_in_the_woods.jpg","types":["State"]},
{"name":"Lucky Coin","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"lucky_coin.jpg","types":["Treasure","Heirloom"]},
{"name":"Lurker","set":"Intrigue, 2E","cost":"2.00","supply":true,"landscape":false,"image_name":"lurker.jpg","types":["Action"]},
{"name":"Madman","set":"Dark Ages","cost":"0.00","supply":true,"landscape":false,"image_name":"madman.jpg","types":["Action"]},
{"name":"Magic Lamp","set":"Nocturne","cost":"0.00","supply":true,"landscape":false,"image_name":"magic_lamp.jpg","types":["Treasure","Heirloom"]},
{"name":"Magpie","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"magpie.jpg","types":["Action"]},
{"name":"Mandarin","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"mandarin.jpg","types":["Action"]},
{"name":"Marauder","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"marauder.jpg","types":["Action","Attack","Looter"]},
{"name":"March","set":"Menagerie","cost":"3.00","supply":true,"landscape":true,"image_name":"march.jpg","types":["Event"]},
{"name":"Margrave","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"margrave.jpg","types":["Action","Attack"]},
{"name":"Market","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"market.jpg","types":["Action"]},
{"name":"Market Square","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"market_square.jpg","types":["Action","Reaction"]},
{"name":"Masquerade","set":"Intrigue","cost":"3.00","supply":true,"landscape":false,"image_name":"masquerade.jpg","types":["Action"]},
{"name":"Mastermind","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"mastermind.jpg","types":["Action","Duration"]},
{"name":"Masterpiece","set":"Guilds","cost":"3.00","supply":true,"landscape":false,"image_name":"masterpiece.jpg","types":["Treasure"]},
{"name":"Menagerie","set":"Cornucopia","cost":"3.00","supply":true,"landscape":false,"image_name":"menagerie.jpg","types":["Action"]},
{"name":"Mercenary","set":"Dark Ages","cost":"0.00","supply":true,"landscape":false,"image_name":"mercenary.jpg","types":["Action","Attack"]},
{"name":"Merchant","set":"Base, 2E","cost":"3.00","supply":true,"landscape":false,"image_name":"merchant.jpg","types":["Action"]},
{"name":"Merchant Guild","set":"Guilds","cost":"5.00","supply":true,"landscape":false,"image_name":"merchant_guild.jpg","types":["Action"]},
{"name":"Merchant Ship","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"merchant_ship.jpg","types":["Action","Duration"]},
{"name":"Messenger","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"messenger.jpg","types":["Action"]},
{"name":"Militia","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"militia.jpg","types":["Action","Attack"]},
{"name":"Mill","set":"Intrigue, 2E","cost":"4.00","supply":true,"landscape":false,"image_name":"mill.jpg","types":["Action","Victory"]},
{"name":"Mine","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"mine.jpg","types":["Action"]},
{"name":"Mining Village","set":"Intrigue","cost":"4.00","supply":true,"landscape":false,"image_name":"mining_village.jpg","types":["Action"]},
{"name":"Minion","set":"Intrigue","cost":"5.00","supply":true,"landscape":false,"image_name":"minion.jpg","types":["Action","Attack"]},
{"name":"Mint","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"mint.jpg","types":["Action"]},
{"name":"Miser","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"miser.jpg","types":["Action"]},
{"name":"Miserable","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"miserable.jpg","types":["State"]},
{"name":"Misery","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"misery.jpg","types":["Hex"]},
{"name":"Mission","set":"Adventures","cost":"4.00","supply":true,"landscape":true,"image_name":"mission.jpg","types":["Event"]},
{"name":"Moat","set":"Base","cost":"2.00","supply":true,"landscape":false,"image_name":"moat.jpg","types":["Action","Reaction"]},
{"name":"Monastery","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"monastery.jpg","types":["Night"]},
{"name":"Moneylender","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"moneylender.jpg","types":["Action"]},
{"name":"Monument","set":"Prosperity","cost":"4.00","supply":true,"landscape":false,"image_name":"monument.jpg","types":["Action"]},
{"name":"Mountain Pass","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"mountain_pass.jpg","types":["Landmark"]},
{"name":"Mountain Village","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"mountain_village.jpg","types":["Action"]},
{"name":"Mountebank","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"mountebank.jpg","types":["Action","Attack"]},
{"name":"Museum","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"museum.jpg","types":["Landmark"]},
{"name":"Mystic","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"mystic.jpg","types":["Action"]},
{"name":"Native Village","set":"Seaside","cost":"2.00","supply":true,"landscape":false,"image_name":"native_village.jpg","types":["Action"]},
{"name":"Navigator","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"navigator.jpg","types":["Action"]},
{"name":"Necromancer","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"necromancer.jpg","types":["Action"]},
{"name":"Necropolis","set":"Dark Ages","cost":"1.00","supply":true,"landscape":false,"image_name":"necropolis.jpg","types":["Action","Shelter"]},
{"name":"Night Watchman","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"night_watchman.jpg","types":["Night"]},
{"name":"Noble Brigand","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"noble_brigand.jpg","types":["Action","Attack"]},
{"name":"Nobles","set":"Intrigue","cost":"6.00","supply":true,"landscape":false,"image_name":"nobles.jpg","types":["Action","Victory"]},
{"name":"Nomad Camp","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"nomad_camp.jpg","types":["Action"]},
{"name":"Oasis","set":"Hinterlands","cost":"3.00","supply":true,"landscape":false,"image_name":"oasis.jpg","types":["Action"]},
{"name":"Obelisk","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"obelisk.jpg","types":["Landmark"]},
{"name":"Old Witch","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"old_witch.jpg","types":["Action","Attack"]},
{"name":"Opulent Castle","set":"Empires","cost":"7.00","supply":false,"landscape":false,"image_name":"opulent_castle.jpg","types":["Action","Victory","Castle"]},
{"name":"Oracle","set":"Hinterlands","cost":"3.00","supply":true,"landscape":false,"image_name":"oracle.jpg","types":["Action","Attack"]},
{"name":"Orchard","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"orchard.jpg","types":["Landmark"]},
{"name":"Outpost","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"outpost.jpg","types":["Action","Duration"]},
{"name":"Overgrown Estate","set":"Dark Ages","cost":"1.00","supply":true,"landscape":false,"image_name":"overgrown_estate.jpg","types":["Victory","Shelter"]},
{"name":"Overlord","set":"Empires","cost":"8.00","supply":true,"landscape":false,"image_name":"overlord.jpg","types":["Action","Command"]},
{"name":"Paddock","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"paddock.jpg","types":["Action"]},
{"name":"Page","set":"Adventures","cost":"2.00","supply":true,"landscape":false,"image_name":"page.jpg","types":["Action","Traveller"]},
{"name":"Pageant","set":"Renaissance","cost":"3.00","supply":true,"landscape":true,"image_name":"pageant.jpg","types":["Project"]},
{"name":"Palace","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"palace.jpg","types":["Landmark"]},
{"name":"Pasture","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"pasture.jpg","types":["Treasure","Victory","Heirloom"]},
{"name":"Pathfinding","set":"Adventures","cost":"8.00","supply":true,"landscape":true,"image_name":"pathfinding.jpg","types":["Event"]},
{"name":"Patrician","set":"Empires","cost":"2.00","supply":true,"landscape":false,"image_name":"patrician.jpg","types":["Action"]},
{"name":"Patrol","set":"Intrigue, 2E","cost":"5.00","supply":true,"landscape":false,"image_name":"patrol.jpg","types":["Action"]},
{"name":"Patron","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"patron.jpg","types":["Action","Reaction"]},
{"name":"Pawn","set":"Intrigue","cost":"2.00","supply":true,"landscape":false,"image_name":"pawn.jpg","types":["Action"]},
{"name":"Pearl Diver","set":"Seaside","cost":"2.00","supply":true,"landscape":false,"image_name":"pearl_diver.jpg","types":["Action"]},
{"name":"Peasant","set":"Adventures","cost":"2.00","supply":true,"landscape":false,"image_name":"peasant.jpg","types":["Action","Traveller"]},
{"name":"Peddler","set":"Prosperity","cost":"8.00","supply":true,"landscape":false,"image_name":"peddler.jpg","types":["Action"]},
{"name":"Philosopher's Stone","set":"Alchemy","cost":"3.50","supply":true,"landscape":false,"image_name":"philosopher's_stone.jpg","types":["Treasure"]},
{"name":"Piazza","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"piazza.jpg","types":["Project"]},
{"name":"Pilgrimage","set":"Adventures","cost":"4.00","supply":true,"landscape":true,"image_name":"pilgrimage.jpg","types":["Event"]},
{"name":"Pillage","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"pillage.jpg","types":["Action","Attack"]},
{"name":"Pirate Ship","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"pirate_ship.jpg","types":["Action","Attack"]},
{"name":"Pixie","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"pixie.jpg","types":["Action","Fate"]},
{"name":"Plague","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"plague.jpg","types":["Hex"]},
{"name":"Plan","set":"Adventures","cost":"3.00","supply":true,"landscape":true,"image_name":"plan.jpg","types":["Event"]},
{"name":"Platinum","set":"Prosperity","cost":"9.00","supply":true,"landscape":false,"image_name":"platinum.jpg","types":["Treasure"]},
{"name":"Plaza","set":"Guilds","cost":"4.00","supply":true,"landscape":false,"image_name":"plaza.jpg","types":["Action"]},
{"name":"Plunder","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"plunder.jpg","types":["Treasure"]},
{"name":"Poacher","set":"Base, 2E","cost":"4.00","supply":true,"landscape":false,"image_name":"poacher.jpg","types":["Action"]},
{"name":"Pooka","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"pooka.jpg","types":["Action"]},
{"name":"Poor House","set":"Dark Ages","cost":"1.00","supply":true,"landscape":false,"image_name":"poor_house.jpg","types":["Action"]},
{"name":"Populate","set":"Menagerie","cost":"0.00","supply":true,"landscape":true,"image_name":"populate.jpg","types":["Event"]},
{"name":"Port","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"port.jpg","types":["Action"]},
{"name":"Possession","set":"Alchemy","cost":"6.50","supply":true,"landscape":false,"image_name":"possession.jpg","types":["Action"]},
{"name":"Potion","set":"Alchemy","cost":"4.00","supply":true,"landscape":false,"image_name":"potion.jpg","types":["Treasure"]},
{"name":"Pouch","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"pouch.jpg","types":["Treasure","Heirloom"]},
{"name":"Poverty","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"poverty.jpg","types":["Hex"]},
{"name":"Priest","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"priest.jpg","types":["Action"]},
{"name":"Prince","set":"Promo","cost":"8.00","supply":true,"landscape":false,"image_name":"prince.jpg","types":["Action"]},
{"name":"Princess","set":"Cornucopia","cost":"0.00","supply":true,"landscape":false,"image_name":"princess.jpg","types":["Action","Prize"]},
{"name":"Procession","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"procession.jpg","types":["Action"]},
{"name":"Province","set":"Base","cost":"8.00","supply":false,"landscape":false,"image_name":"province.jpg","types":["Victory"]},
{"name":"Pursue","set":"Menagerie","cost":"2.00","supply":true,"landscape":true,"image_name":"pursue.jpg","types":["Event"]},
{"name":"Quarry","set":"Prosperity","cost":"4.00","supply":true,"landscape":false,"image_name":"quarry.jpg","types":["Treasure"]},
{"name":"Quest","set":"Adventures","cost":"0.00","supply":true,"landscape":true,"image_name":"quest.jpg","types":["Event"]},
{"name":"Rabble","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"rabble.jpg","types":["Action","Attack"]},
{"name":"Raid","set":"Adventures","cost":"5.00","supply":true,"landscape":true,"image_name":"raid.jpg","types":["Event"]},
{"name":"Raider","set":"Nocturne","cost":"6.00","supply":true,"landscape":false,"image_name":"raider.jpg","types":["Night","Duration","Attack"]},
{"name":"Ranger","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"ranger.jpg","types":["Action"]},
{"name":"Ratcatcher","set":"Adventures","cost":"2.00","supply":true,"landscape":false,"image_name":"ratcatcher.jpg","types":["Action","Reserve"]},
{"name":"Rats","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"rats.jpg","types":["Action"]},
{"name":"Raze","set":"Adventures","cost":"2.00","supply":true,"landscape":false,"image_name":"raze.jpg","types":["Action"]},
{"name":"Reap","set":"Menagerie","cost":"7.00","supply":true,"landscape":true,"image_name":"reap.jpg","types":["Event"]},
{"name":"Rebuild","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"rebuild.jpg","types":["Action"]},
{"name":"Recruiter","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"recruiter.jpg","types":["Action"]},
{"name":"Relic","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"relic.jpg","types":["Treasure","Attack"]},
{"name":"Remake","set":"Cornucopia","cost":"4.00","supply":true,"landscape":false,"image_name":"remake.jpg","types":["Action"]},
{"name":"Remodel","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"remodel.jpg","types":["Action"]},
{"name":"Replace","set":"Intrigue, 2E","cost":"5.00","supply":true,"landscape":false,"image_name":"replace.jpg","types":["Action","Attack"]},
{"name":"Research","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"research.jpg","types":["Action","Duration"]},
{"name":"Ride","set":"Menagerie","cost":"2.00","supply":true,"landscape":true,"image_name":"ride.jpg","types":["Event"]},
{"name":"Ritual","set":"Empires","cost":"4.00","supply":true,"landscape":true,"image_name":"ritual.jpg","types":["Event"]},
{"name":"Road Network","set":"Renaissance","cost":"5.00","supply":true,"landscape":true,"image_name":"road_network.jpg","types":["Project"]},
{"name":"Rocks","set":"Empires","cost":"4.00","supply":true,"landscape":false,"image_name":"rocks.jpg","types":["Treasure"]},
{"name":"Rogue","set":"Dark Ages","cost":"5.00","supply":true,"landscape":false,"image_name":"rogue.jpg","types":["Action","Attack"]},
{"name":"Royal Blacksmith","set":"Empires","cost":"8.00","supply":true,"landscape":false,"image_name":"royal_blacksmith.jpg","types":["Action"]},
{"name":"Royal Carriage","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"royal_carriage.jpg","types":["Action","Reserve"]},
{"name":"Royal Seal","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"royal_seal.jpg","types":["Treasure"]},
{"name":"Ruined Library","set":"Dark Ages","cost":"0.00","supply":false,"landscape":false,"image_name":"ruined_library.jpg","types":["Action","Ruins"]},
{"name":"Ruined Market","set":"Dark Ages","cost":"0.00","supply":false,"landscape":false,"image_name":"ruined_market.jpg","types":["Action","Ruins"]},
{"name":"Ruined Village","set":"Dark Ages","cost":"0.00","supply":false,"landscape":false,"image_name":"ruined_village.jpg","types":["Action","Ruins"]},
{"name":"Saboteur","set":"Intrigue, 1E","cost":"5.00","supply":true,"landscape":false,"image_name":"saboteur.jpg","types":["Action","Attack"]},
{"name":"Sacred Grove","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"sacred_grove.jpg","types":["Action","Fate"]},
{"name":"Sacrifice","set":"Empires","cost":"4.00","supply":true,"landscape":false,"image_name":"sacrifice.jpg","types":["Action"]},
{"name":"Sage","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"sage.jpg","types":["Action"]},
{"name":"Salt the Earth","set":"Empires","cost":"4.00","supply":true,"landscape":true,"image_name":"salt_the_earth.jpg","types":["Event"]},
{"name":"Salvager","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"salvager.jpg","types":["Action"]},
{"name":"Sanctuary","set":"Menagerie","cost":"5.00","supply":true,"landscape":false,"image_name":"sanctuary.jpg","types":["Action"]},
{"name":"Sauna","set":"Promo","cost":"4.00","supply":true,"landscape":false,"image_name":"sauna.jpg","types":["Action"]},
{"name":"Save","set":"Adventures","cost":"1.00","supply":true,"landscape":true,"image_name":"save.jpg","types":["Event"]},
{"name":"Scavenger","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"scavenger.jpg","types":["Action"]},
{"name":"Scepter","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"scepter.jpg","types":["Treasure"]},
{"name":"Scheme","set":"Hinterlands","cost":"3.00","supply":true,"landscape":false,"image_name":"scheme.jpg","types":["Action"]},
{"name":"Scholar","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"scholar.jpg","types":["Action"]},
{"name":"Scout","set":"Intrigue, 1E","cost":"4.00","supply":true,"landscape":false,"image_name":"scout.jpg","types":["Action"]},
{"name":"Scouting Party","set":"Adventures","cost":"2.00","supply":true,"landscape":true,"image_name":"scouting_party.jpg","types":["Event"]},
{"name":"Scrap","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"scrap.jpg","types":["Action"]},
{"name":"Scrying Pool","set":"Alchemy","cost":"2.50","supply":true,"landscape":false,"image_name":"scrying_pool.jpg","types":["Action","Attack"]},
{"name":"Sculptor","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"sculptor.jpg","types":["Action"]},
{"name":"Sea Hag","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"sea_hag.jpg","types":["Action","Attack"]},
{"name":"Seaway","set":"Adventures","cost":"5.00","supply":true,"landscape":true,"image_name":"seaway.jpg","types":["Event"]},
{"name":"Secret Cave","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"secret_cave.jpg","types":["Action","Duration"]},
{"name":"Secret Chamber","set":"Intrigue, 1E","cost":"2.00","supply":true,"landscape":false,"image_name":"secret_chamber.jpg","types":["Action","Reaction"]},
{"name":"Secret Passage","set":"Intrigue, 2E","cost":"4.00","supply":true,"landscape":false,"image_name":"secret_passage.jpg","types":["Action"]},
{"name":"Seer","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"seer.jpg","types":["Action"]},
{"name":"Seize the Day","set":"Menagerie","cost":"4.00","supply":true,"landscape":true,"image_name":"seize_the_day.jpg","types":["Event"]},
{"name":"Sentry","set":"Base, 2E","cost":"5.00","supply":true,"landscape":false,"image_name":"sentry.jpg","types":["Action"]},
{"name":"Settlers","set":"Empires","cost":"2.00","supply":true,"landscape":false,"image_name":"settlers.jpg","types":["Action"]},
{"name":"Sewers","set":"Renaissance","cost":"3.00","supply":true,"landscape":true,"image_name":"sewers.jpg","types":["Project"]},
{"name":"Shanty Town","set":"Intrigue","cost":"3.00","supply":true,"landscape":false,"image_name":"shanty_town.jpg","types":["Action"]},
{"name":"Sheepdog","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"sheepdog.jpg","types":["Action","Reaction"]},
{"name":"Shepherd","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"shepherd.jpg","types":["Action"]},
{"name":"Silk Merchant","set":"Renaissance","cost":"4.00","supply":true,"landscape":false,"image_name":"silk_merchant.jpg","types":["Action"]},
{"name":"Silk Road","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"silk_road.jpg","types":["Victory"]},
{"name":"Silos","set":"Renaissance","cost":"4.00","supply":true,"landscape":true,"image_name":"silos.jpg","types":["Project"]},
{"name":"Silver","set":"Base","cost":"3.00","supply":false,"landscape":false,"image_name":"silver.jpg","types":["Treasure"]},
{"name":"Sinister Plot","set":"Renaissance","cost":"4.00","supply":true,"landscape":true,"image_name":"sinister_plot.jpg","types":["Project"]},
{"name":"Sir Bailey","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"sir_bailey.jpg","types":["Action","Attack","Knight"]},
{"name":"Sir Destry","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"sir_destry.jpg","types":["Action","Attack","Knight"]},
{"name":"Sir Martin","set":"Dark Ages","cost":"4.00","supply":false,"landscape":false,"image_name":"sir_martin.jpg","types":["Action","Attack","Knight"]},
{"name":"Sir Michael","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"sir_michael.jpg","types":["Action","Attack","Knight"]},
{"name":"Sir Vander","set":"Dark Ages","cost":"5.00","supply":false,"landscape":false,"image_name":"sir_vander.jpg","types":["Action","Attack","Knight"]},
{"name":"Skulk","set":"Nocturne","cost":"4.00","supply":true,"landscape":false,"image_name":"skulk.jpg","types":["Action","Attack","Doom"]},
{"name":"Sleigh","set":"Menagerie","cost":"2.00","supply":true,"landscape":false,"image_name":"sleigh.jpg","types":["Action","Reaction"]},
{"name":"Small Castle","set":"Empires","cost":"5.00","supply":false,"landscape":false,"image_name":"small_castle.jpg","types":["Action","Victory","Castle"]},
{"name":"Smithy","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"smithy.jpg","types":["Action"]},
{"name":"Smugglers","set":"Seaside","cost":"3.00","supply":true,"landscape":false,"image_name":"smugglers.jpg","types":["Action"]},
{"name":"Snowy Village","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"snowy_village.jpg","types":["Action"]},
{"name":"Soldier","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"soldier.jpg","types":["Action","Attack","Traveller"]},
{"name":"Soothsayer","set":"Guilds","cost":"5.00","supply":true,"landscape":false,"image_name":"soothsayer.jpg","types":["Action","Attack"]},
{"name":"Spice Merchant","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"spice_merchant.jpg","types":["Action"]},
{"name":"Spices","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"spices.jpg","types":["Treasure"]},
{"name":"Spoils","set":"Dark Ages","cost":"0.00","supply":true,"landscape":false,"image_name":"spoils.jpg","types":["Treasure"]},
{"name":"Sprawling Castle","set":"Empires","cost":"8.00","supply":false,"landscape":false,"image_name":"sprawling_castle.jpg","types":["Victory","Castle"]},
{"name":"Spy","set":"Base, 1E","cost":"4.00","supply":true,"landscape":false,"image_name":"spy.jpg","types":["Action","Attack"]},
{"name":"Squire","set":"Dark Ages","cost":"2.00","supply":true,"landscape":false,"image_name":"squire.jpg","types":["Action"]},
{"name":"Stables","set":"Hinterlands","cost":"5.00","supply":true,"landscape":false,"image_name":"stables.jpg","types":["Action"]},
{"name":"Stampede","set":"Menagerie","cost":"5.00","supply":true,"landscape":true,"image_name":"stampede.jpg","types":["Event"]},
{"name":"Star Chart","set":"Renaissance","cost":"3.00","supply":true,"landscape":true,"image_name":"star_chart.jpg","types":["Project"]},
{"name":"Stash","set":"Promo","cost":"5.00","supply":true,"landscape":false,"image_name":"stash.jpg","types":["Treasure"]},
{"name":"Steward","set":"Intrigue","cost":"3.00","supply":true,"landscape":false,"image_name":"steward.jpg","types":["Action"]},
{"name":"Stockpile","set":"Menagerie","cost":"3.00","supply":true,"landscape":false,"image_name":"stockpile.jpg","types":["Treasure"]},
{"name":"Stonemason","set":"Guilds","cost":"2.00","supply":true,"landscape":false,"image_name":"stonemason.jpg","types":["Action"]},
{"name":"Storeroom","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"storeroom.jpg","types":["Action"]},
{"name":"Storyteller","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"storyteller.jpg","types":["Action"]},
{"name":"Summon","set":"Promo","cost":"5.00","supply":true,"landscape":true,"image_name":"summon.jpg","types":["Event"]},
{"name":"Supplies","set":"Menagerie","cost":"2.00","supply":true,"landscape":false,"image_name":"supplies.jpg","types":["Treasure"]},
{"name":"Survivors","set":"Dark Ages","cost":"0.00","supply":false,"landscape":false,"image_name":"survivors.jpg","types":["Action","Ruins"]},
{"name":"Swamp Hag","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"swamp_hag.jpg","types":["Action","Attack","Duration"]},
{"name":"Swashbuckler","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"swashbuckler.jpg","types":["Action"]},
{"name":"Swindler","set":"Intrigue","cost":"3.00","supply":true,"landscape":false,"image_name":"swindler.jpg","types":["Action","Attack"]},
{"name":"Tactician","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"tactician.jpg","types":["Action","Duration"]},
{"name":"Talisman","set":"Prosperity","cost":"4.00","supply":true,"landscape":false,"image_name":"talisman.jpg","types":["Treasure"]},
{"name":"Tax","set":"Empires","cost":"2.00","supply":true,"landscape":true,"image_name":"tax.jpg","types":["Event"]},
{"name":"Taxman","set":"Guilds","cost":"4.00","supply":true,"landscape":false,"image_name":"taxman.jpg","types":["Action","Attack"]},
{"name":"Teacher","set":"Adventures","cost":"6.00","supply":true,"landscape":false,"image_name":"teacher.jpg","types":["Action","Reserve"]},
{"name":"Temple","set":"Empires","cost":"4.00","supply":true,"landscape":false,"image_name":"temple.jpg","types":["Action","Gathering"]},
{"name":"The Earth's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_earth's_gift.jpg","types":["Boon"]},
{"name":"The Field's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_field's_gift.jpg","types":["Boon"]},
{"name":"The Flame's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_flame's_gift.jpg","types":["Boon"]},
{"name":"The Forest's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_forest's_gift.jpg","types":["Boon"]},
{"name":"The Moon's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_moon's_gift.jpg","types":["Boon"]},
{"name":"The Mountain's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_mountain's_gift.jpg","types":["Boon"]},
{"name":"The River's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_river's_gift.jpg","types":["Boon"]},
{"name":"The Sea's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_sea's_gift.jpg","types":["Boon"]},
{"name":"The Sky's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_sky's_gift.jpg","types":["Boon"]},
{"name":"The Sun's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_sun's_gift.jpg","types":["Boon"]},
{"name":"The Swamp's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_swamp's_gift.jpg","types":["Boon"]},
{"name":"The Wind's Gift","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"the_wind's_gift.jpg","types":["Boon"]},
{"name":"Thief","set":"Base, 1E","cost":"4.00","supply":true,"landscape":false,"image_name":"thief.jpg","types":["Action","Attack"]},
{"name":"Throne Room","set":"Base","cost":"4.00","supply":true,"landscape":false,"image_name":"throne_room.jpg","types":["Action"]},
{"name":"Toil","set":"Menagerie","cost":"2.00","supply":true,"landscape":true,"image_name":"toil.jpg","types":["Event"]},
{"name":"Tomb","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"tomb.jpg","types":["Landmark"]},
{"name":"Tormentor","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"tormentor.jpg","types":["Action","Attack","Doom"]},
{"name":"Torturer","set":"Intrigue","cost":"5.00","supply":true,"landscape":false,"image_name":"torturer.jpg","types":["Action","Attack"]},
{"name":"Tournament","set":"Cornucopia","cost":"4.00","supply":true,"landscape":false,"image_name":"tournament.jpg","types":["Action"]},
{"name":"Tower","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"tower.jpg","types":["Landmark"]},
{"name":"Tracker","set":"Nocturne","cost":"2.00","supply":true,"landscape":false,"image_name":"tracker.jpg","types":["Action","Fate"]},
{"name":"Trade","set":"Adventures","cost":"5.00","supply":true,"landscape":true,"image_name":"trade.jpg","types":["Event"]},
{"name":"Trader","set":"Hinterlands","cost":"4.00","supply":true,"landscape":false,"image_name":"trader.jpg","types":["Action","Reaction"]},
{"name":"Trade Route","set":"Prosperity","cost":"3.00","supply":true,"landscape":false,"image_name":"trade_route.jpg","types":["Action"]},
{"name":"Trading Post","set":"Intrigue","cost":"5.00","supply":true,"landscape":false,"image_name":"trading_post.jpg","types":["Action"]},
{"name":"Tragic Hero","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"tragic_hero.jpg","types":["Action"]},
{"name":"Training","set":"Adventures","cost":"6.00","supply":true,"landscape":true,"image_name":"training.jpg","types":["Event"]},
{"name":"Transmogrify","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"transmogrify.jpg","types":["Action","Reserve"]},
{"name":"Transmute","set":"Alchemy","cost":"0.50","supply":true,"landscape":false,"image_name":"transmute.jpg","types":["Action"]},
{"name":"Transport","set":"Menagerie","cost":"3.00","supply":true,"landscape":true,"image_name":"transport.jpg","types":["Event"]},
{"name":"Travelling Fair","set":"Adventures","cost":"2.00","supply":true,"landscape":true,"image_name":"travelling_fair.jpg","types":["Event"]},
{"name":"Treasure Chest","set":"Renaissance","cost":null,"supply":false,"landscape":true,"image_name":"treasure_chest.jpg","types":["Artifact"]},
{"name":"Treasure Hunter","set":"Adventures","cost":"3.00","supply":true,"landscape":false,"image_name":"treasure_hunter.jpg","types":["Action","Traveller"]},
{"name":"Treasure Map","set":"Seaside","cost":"4.00","supply":true,"landscape":false,"image_name":"treasure_map.jpg","types":["Action"]},
{"name":"Treasurer","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"treasurer.jpg","types":["Action"]},
{"name":"Treasure Trove","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"treasure_trove.jpg","types":["Treasure"]},
{"name":"Treasury","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"treasury.jpg","types":["Action"]},
{"name":"Tribute","set":"Intrigue, 1E","cost":"5.00","supply":true,"landscape":false,"image_name":"tribute.jpg","types":["Action"]},
{"name":"Triumph","set":"Empires","cost":"5.00","supply":true,"landscape":true,"image_name":"triumph.jpg","types":["Event"]},
{"name":"Triumphal Arch","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"triumphal_arch.jpg","types":["Landmark"]},
{"name":"Trusty Steed","set":"Cornucopia","cost":"0.00","supply":true,"landscape":false,"image_name":"trusty_steed.jpg","types":["Action","Prize"]},
{"name":"Tunnel","set":"Hinterlands","cost":"3.00","supply":true,"landscape":false,"image_name":"tunnel.jpg","types":["Victory","Reaction"]},
{"name":"Twice Miserable","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"twice_miserable.jpg","types":["State"]},
{"name":"University","set":"Alchemy","cost":"2.50","supply":true,"landscape":false,"image_name":"university.jpg","types":["Action"]},
{"name":"Upgrade","set":"Intrigue","cost":"5.00","supply":true,"landscape":false,"image_name":"upgrade.jpg","types":["Action"]},
{"name":"Urchin","set":"Dark Ages","cost":"3.00","supply":true,"landscape":false,"image_name":"urchin.jpg","types":["Action","Attack"]},
{"name":"Vagrant","set":"Dark Ages","cost":"2.00","supply":true,"landscape":false,"image_name":"vagrant.jpg","types":["Action"]},
{"name":"Vampire","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"vampire.jpg","types":["Night","Attack","Doom"]},
{"name":"Vassal","set":"Base, 2E","cost":"3.00","supply":true,"landscape":false,"image_name":"vassal.jpg","types":["Action"]},
{"name":"Vault","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"vault.jpg","types":["Action"]},
{"name":"Venture","set":"Prosperity","cost":"5.00","supply":true,"landscape":false,"image_name":"venture.jpg","types":["Treasure"]},
{"name":"Villa","set":"Empires","cost":"4.00","supply":true,"landscape":false,"image_name":"villa.jpg","types":["Action"]},
{"name":"Village","set":"Base","cost":"3.00","supply":true,"landscape":false,"image_name":"village.jpg","types":["Action"]},
{"name":"Village Green","set":"Menagerie","cost":"4.00","supply":true,"landscape":false,"image_name":"village_green.jpg","types":["Action","Duration","Reaction"]},
{"name":"Villain","set":"Renaissance","cost":"5.00","supply":true,"landscape":false,"image_name":"villain.jpg","types":["Action","Attack"]},
{"name":"Vineyard","set":"Alchemy","cost":"0.50","supply":true,"landscape":false,"image_name":"vineyard.jpg","types":["Victory"]},
{"name":"Wall","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"wall.jpg","types":["Landmark"]},
{"name":"Walled Village","set":"Promo","cost":"4.00","supply":true,"landscape":false,"image_name":"walled_village.jpg","types":["Action"]},
{"name":"Wandering Minstrel","set":"Dark Ages","cost":"4.00","supply":true,"landscape":false,"image_name":"wandering_minstrel.jpg","types":["Action"]},
{"name":"War","set":"Nocturne","cost":null,"supply":false,"landscape":true,"image_name":"war.jpg","types":["Hex"]},
{"name":"Warehouse","set":"Seaside","cost":"3.00","supply":true,"landscape":false,"image_name":"warehouse.jpg","types":["Action"]},
{"name":"Warrior","set":"Adventures","cost":"4.00","supply":true,"landscape":false,"image_name":"warrior.jpg","types":["Action","Attack","Traveller"]},
{"name":"Watchtower","set":"Prosperity","cost":"3.00","supply":true,"landscape":false,"image_name":"watchtower.jpg","types":["Action","Reaction"]},
{"name":"Wayfarer","set":"Menagerie","cost":"6.00","supply":true,"landscape":false,"image_name":"wayfarer.jpg","types":["Action"]},
{"name":"Way of the Butterfly","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_butterfly.jpg","types":["Way"]},
{"name":"Way of the Camel","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_camel.jpg","types":["Way"]},
{"name":"Way of the Chameleon","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_chameleon.jpg","types":["Way"]},
{"name":"Way of the Frog","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_frog.jpg","types":["Way"]},
{"name":"Way of the Goat","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_goat.jpg","types":["Way"]},
{"name":"Way of the Horse","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_horse.jpg","types":["Way"]},
{"name":"Way of the Mole","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_mole.jpg","types":["Way"]},
{"name":"Way of the Monkey","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_monkey.jpg","types":["Way"]},
{"name":"Way of the Mouse","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_mouse.jpg","types":["Way"]},
{"name":"Way of the Mule","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_mule.jpg","types":["Way"]},
{"name":"Way of the Otter","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_otter.jpg","types":["Way"]},
{"name":"Way of the Owl","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_owl.jpg","types":["Way"]},
{"name":"Way of the Ox","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_ox.jpg","types":["Way"]},
{"name":"Way of the Pig","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_pig.jpg","types":["Way"]},
{"name":"Way of the Rat","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_rat.jpg","types":["Way"]},
{"name":"Way of the Seal","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_seal.jpg","types":["Way"]},
{"name":"Way of the Sheep","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_sheep.jpg","types":["Way"]},
{"name":"Way of the Squirrel","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_squirrel.jpg","types":["Way"]},
{"name":"Way of the Turtle","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_turtle.jpg","types":["Way"]},
{"name":"Way of the Worm","set":"Menagerie","cost":null,"supply":true,"landscape":true,"image_name":"way_of_the_worm.jpg","types":["Way"]},
{"name":"Wedding","set":"Empires","cost":"7.00","supply":true,"landscape":true,"image_name":"wedding.jpg","types":["Event"]},
{"name":"Werewolf","set":"Nocturne","cost":"5.00","supply":true,"landscape":false,"image_name":"werewolf.jpg","types":["Action","Night","Attack","Doom"]},
{"name":"Wharf","set":"Seaside","cost":"5.00","supply":true,"landscape":false,"image_name":"wharf.jpg","types":["Action","Duration"]},
{"name":"Wild Hunt","set":"Empires","cost":"5.00","supply":true,"landscape":false,"image_name":"wild_hunt.jpg","types":["Action","Gathering"]},
{"name":"Will-o'-Wisp","set":"Nocturne","cost":"0.00","supply":true,"landscape":false,"image_name":"will-o'-wisp.jpg","types":["Action","Spirit"]},
{"name":"Windfall","set":"Empires","cost":"5.00","supply":true,"landscape":true,"image_name":"windfall.jpg","types":["Event"]},
{"name":"Wine Merchant","set":"Adventures","cost":"5.00","supply":true,"landscape":false,"image_name":"wine_merchant.jpg","types":["Action","Reserve"]},
{"name":"Wish","set":"Nocturne","cost":"0.00","supply":true,"landscape":false,"image_name":"wish.jpg","types":["Action"]},
{"name":"Wishing Well","set":"Intrigue","cost":"3.00","supply":true,"landscape":false,"image_name":"wishing_well.jpg","types":["Action"]},
{"name":"Witch","set":"Base","cost":"5.00","supply":true,"landscape":false,"image_name":"witch.jpg","types":["Action","Attack"]},
{"name":"Wolf Den","set":"Empires","cost":null,"supply":true,"landscape":true,"image_name":"wolf_den.jpg","types":["Landmark"]},
{"name":"Woodcutter","set":"Base, 1E","cost":"3.00","supply":true,"landscape":false,"image_name":"woodcutter.jpg","types":["Action"]},
{"name":"Worker's Village","set":"Prosperity","cost":"4.00","supply":true,"landscape":false,"image_name":"worker's_village.jpg","types":["Action"]},
{"name":"Workshop","set":"Base","cost":"3.00","supply":true,"landscape":false,"image_name":"workshop.jpg","types":["Action"]},
{"name":"Young Witch","set":"Cornucopia","cost":"4.00","supply":true,"landscape":false,"image_name":"young_witch.jpg","types":["Action","Attack"]},
{"name":"Zombie Apprentice","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"zombie_apprentice.jpg","types":["Action","Zombie"]},
{"name":"Zombie Mason","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"zombie_mason.jpg","types":["Action","Zombie"]},
{"name":"Zombie Spy","set":"Nocturne","cost":"3.00","supply":true,"landscape":false,"image_name":"zombie_spy.jpg","types":["Action","Zombie"]}]

export default CARDS;